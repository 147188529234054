import React from 'react';
import { hydrate } from 'react-dom';
import { Globals, StyleReset } from '@bwoty-web/ui-kit';
import MainContainer from '../shared/components/MainContainer';
import InitialStateProvider from '../shared/context/InitialState';
import LocationProvider from '../shared/context/Location';
import HotelsProvider from '../shared/context/Hotels';
import { updateAllSearchParams } from '../shared/utils/searchParams';

const initialState = window.__INITIAL_HOTELFINDER_STATE__;
if (initialState) updateAllSearchParams(initialState.location.query);

const { isStandalone } = initialState;
const { utils } = initialState;
const { siteId } = utils;

const hotelfinderRoot = document.getElementById('hotelfinder');

if (hotelfinderRoot) {
  hydrate(
    <InitialStateProvider initialState={initialState}>
      <LocationProvider>
        <HotelsProvider>
          <Globals siteId={siteId}>
            {isStandalone && <StyleReset />}
            <MainContainer />
          </Globals>
        </HotelsProvider>
      </LocationProvider>
    </InitialStateProvider>,
    hotelfinderRoot
  );
}

if (process.env.NODE_ENV !== 'production') {
  window.React = React; // enable debugger
}
