import React from 'react';
import { GuestRatingSlider } from '@bwoty-web/ui-kit';
import SliderWrapper from './SliderWrapper';
import Heading from './Heading';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

const FilterGuestRating = (props) => {
  const { heading, label, searchParams, refetchHotels } = props;

  const hidden = searchParams.filter === 'true';

  const guestRatingFrom = parseInt(searchParams.guestRatingFrom || '30', 10) / 10 || 3;
  const guestRatingTo = parseInt(searchParams.guestRatingTo || '50', 10) / 10 || 5;

  const handleChangeComplete = ({ lowValue, highValue }) => {
    refetchHotels({
      newSearchParams: {
        guestRatingFrom: lowValue !== 3 ? `${lowValue * 10}` : '',
        guestRatingTo: highValue !== 5 ? `${highValue * 10}` : '',
      },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: {
          travelFilterCategory: 'Grades',
          travelFilterValue: 'Guest rating',
        },
      },
    });
  };

  return (
    <div>
      <Heading>{heading}</Heading>
      <SliderWrapper>
        <GuestRatingSlider
          uniqueId="guest-rating_filter"
          filterSliderToLabel={label || 'to'}
          lowValue={guestRatingFrom}
          highValue={guestRatingTo}
          hidden={hidden}
          onChangeComplete={handleChangeComplete}
        />
      </SliderWrapper>
    </div>
  );
};

FilterGuestRating.defaultProps = defaultProps;

export default FilterGuestRating;
