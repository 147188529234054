import fetch from 'isomorphic-fetch';

async function importNodePackages() {
  const [{ httpAgent, httpsAgent }] = await Promise.all([
    import('./httpAgent'),
  ]);

  if (!globalThis.fetch) {
    globalThis.fetch = fetch;
    globalThis.fetch.httpAgent = httpAgent;
    globalThis.fetch.httpsAgent = httpsAgent;
  }

  return { httpAgent, httpsAgent };
}

const customFetch = async (url, options = {}) => {
  const isNode = typeof window === 'undefined';

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 15000);
  const client = () => fetch(url, { ...options, signal: controller.signal });

  if (!isNode) return client();

  const { httpAgent, httpsAgent } = await importNodePackages();



  // eslint-disable-next-line no-param-reassign
  options.agent = url.startsWith('https://') ? httpsAgent : httpAgent;

  try {
    const response = await client();
    clearTimeout(timeoutId);
    return response;
  } catch (error) {
    clearTimeout(timeoutId);
    throw error;
  }
};

export default customFetch;
