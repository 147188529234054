import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { getHotels } from '../api';
import { useInitialState } from './useInitialState';
import { useMucd } from './useMucd';
import { useHeaders } from './useHeaders';
import { usePredefined } from './usePredefined';
import { getPax, getRoomAges, getRoomAgesString, isPaxValid } from './usePax';
import { useGeoId } from './useGeoId';
import { getDepartureString, getSelectedDepartures } from './useDepartures';
import { getDepartureDate, getDepartureDateInterval } from './useDepartureDate';
import { getDurationFromSearchParam } from './useDuration';
import { getMonths } from './useMonths';
import { getOrderBy } from './useOrderBy';
import { generateHotelFinderApiKey } from '../utils/generateHotelFinderApiKey';
import { getToday } from '../utils/getToday';
import { MARKET_UNIT_DICTIONARY } from '../utils/filterDictionaries';
import { usePreparedData } from './usePreparedData';
import { useDefaultSearchQuery } from './useDefaultSearchQuery';
import { getMergedFilters } from './useMergedFilters';
import { initialAnalyticsData, useAnalytics } from './useAnalytics';
import { useLocationContext } from '../context/Location';

const getLastPageNumber = (totalHotelCount) => {
  return Math.ceil(totalHotelCount / 10);
};

const modifyTripPrice = (tripPriceRaw) => {
  if (!tripPriceRaw) {
    return {};
  }
  return {
    min: tripPriceRaw.minPrice,
    max: tripPriceRaw.maxPrice,
  };
};

const facetCountsToDict = (facetCountsArray = []) => {
  return facetCountsArray.reduce((acc, item) => {
    acc[item.key] = item.count;
    return acc;
  }, {});
};

export const useHotels = () => {
  const initialState = useInitialState();
  const location = useLocationContext();
  const [hotelsResult, setHotelsResult] = useState({
    entities: {},
    ids: [],
    totalCount: 0,
    count: 0,
    messages: null,
    ...initialState.hotels,
    tripPrice: modifyTripPrice(initialState?.hotels?.tripPrice),
    facetsCounts: facetCountsToDict(initialState?.hotels?.facetsCounts),
  });
  const [isLoadingInitialHotels, setLoadingInitialHotels] = useState(
    !initialState.didServerRenderHotels
  );
  const [isLoadingRefetchHotels, setLoadingRefetchHotels] = useState(false);
  const [isLoadingMoreHotels, setLoadingMoreHotels] = useState(false);

  const analytics = useAnalytics(hotelsResult, location);

  const { allMonths } = usePreparedData();

  const mucd = useMucd();
  const geoId = useGeoId();
  const headers = useHeaders();
  const predefined = usePredefined();
  const defaults = useDefaultSearchQuery();

  const { searchParams: sp, actions: locationActions } = location;
  const searchParams = useMemo(() => ({ ...predefined, ...sp }), [predefined, sp]);
  const { p: currentPage } = searchParams;
  const currentFiltersRef = useRef({ ...searchParams });
  const resetFiltersInProgress = useRef(false);

  useEffect(() => {
    if (isLoadingRefetchHotels || resetFiltersInProgress.current) {
      return;
    }
    currentFiltersRef.current = { ...currentFiltersRef.current, ...searchParams };
  }, [searchParams, isLoadingRefetchHotels]);

  const fetchHotels = useCallback(
    async ({
      isInitialQuery,
      shouldRefetch = false,
      newSearchParams = {},
      invisibleUrlParams = {},
      analyticsData = initialAnalyticsData,
      onCompleted = () => {},
      clearAllFilters = false,
    }) => {
      if (resetFiltersInProgress.current && !clearAllFilters) {
        return;
      }
      const combinedParams = clearAllFilters
        ? { ...newSearchParams }
        : { ...currentFiltersRef.current, ...newSearchParams };

      currentFiltersRef.current = combinedParams;

      const updatedParams = locationActions.setMultipleSearchParams(
        combinedParams,
        invisibleUrlParams
      );

      analytics.setAnalyticsData(analyticsData);

      const [selectedMonths, monthsStringg] = getMonths(updatedParams, allMonths);
      const roomAgess = getRoomAges(getRoomAgesString(updatedParams, defaults));
      const paxx = getPax(roomAgess);

      const key = generateHotelFinderApiKey({
        isInitialQuery,
        isMap: updatedParams?.view === 'map',
        currentPage: updatedParams.p,
        departureDate: getDepartureDate(updatedParams),
        months: selectedMonths,
        geoId,
        mergedFilters: getMergedFilters(updatedParams),
        orderBy: getOrderBy(updatedParams),
        departureString: getDepartureString(
          getSelectedDepartures(updatedParams, defaults, initialState.allDepartures, mucd)
        ),
        duration: getDurationFromSearchParam(updatedParams),
        todayString: getToday(),
        marketUnitKey: MARKET_UNIT_DICTIONARY[mucd], // MARKET UNIT KEY (MODIFIED FOR HOTEL FINDER API)
        pax: getPax(roomAgess),
        hotelType: updatedParams?.type || 'A',
        offset: getDepartureDateInterval(updatedParams),
        monthsString: monthsStringg,
      });

      const config = {
        mucd,
        headers,
        paxAges: roomAgess,
        isInvalidPax: !isPaxValid(paxx),
        origoUrl: initialState.clientOrigoUrl,
      };

      const res = await getHotels(key, config);
      setHotelsResult({
        ...res,
        entities: { ...(shouldRefetch ? {} : hotelsResult.entities), ...res.entities },
        ids: [...(shouldRefetch ? [] : hotelsResult.ids), ...res.ids],
        facetsCounts: facetCountsToDict(res.facetsCounts),
        tripPrice: modifyTripPrice(res.tripPrice),
      });
      onCompleted();
      resetFiltersInProgress.current = false;
    },
    [
      allMonths,
      analytics,
      defaults,
      geoId,
      headers,
      hotelsResult.entities,
      hotelsResult.ids,
      initialState.allDepartures,
      locationActions,
      mucd,
      initialState.clientOrigoUrl,
    ]
  );

  useEffect(() => {
    if (initialState.didServerRenderHotels) return;
    setLoadingInitialHotels(true);
    fetchHotels({
      isInitialQuery: true,
      onCompleted: () => setLoadingInitialHotels(false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.didServerRenderHotels]);

  const fetchMoreHotels = () => {
    setLoadingMoreHotels(true);
    fetchHotels({
      isInitialQuery: false,
      newSearchParams: {
        p: parseInt(currentPage, 10) + 1,
      },
      onCompleted: () => setLoadingMoreHotels(false),
    });
  };

  const refetchHotels = ({ newSearchParams, analyticsData }) => {
    if (isLoadingRefetchHotels || resetFiltersInProgress.current) {
      return;
    }

    const shouldClearAllFilters = newSearchParams === undefined;
    if (shouldClearAllFilters) {
      resetFiltersInProgress.current = true;
      setLoadingRefetchHotels(true);
      fetchHotels({
        isInitialQuery: true,
        shouldRefetch: true,
        newSearchParams: { p: '1' },
        analyticsData,
        clearAllFilters: true,
        onCompleted: () => {
          currentFiltersRef.current = {};
          setLoadingRefetchHotels(false);
          resetFiltersInProgress.current = false;
        },
      });
      return;
    }

    const latestParams = { ...currentFiltersRef.current };
    setLoadingRefetchHotels(true);
    fetchHotels({
      isInitialQuery: true,
      shouldRefetch: true,
      newSearchParams: { ...latestParams, ...newSearchParams, p: '1' },
      analyticsData,
      onCompleted: () => {
        setLoadingRefetchHotels(false);
      },
    });
  };

  const fetchAllHotels = () => {
    setLoadingRefetchHotels(true);
    fetchHotels({
      isInitialQuery: true,
      shouldRefetch: true,
      newSearchParams: { p: getLastPageNumber(hotelsResult.totalCount) },
      onCompleted: () => setLoadingRefetchHotels(false),
    });
  };

  return {
    hotels: hotelsResult.entities,
    hotelIds: hotelsResult.ids,
    facetCounts: hotelsResult.facetsCounts,
    tripPrice: hotelsResult.tripPrice,
    hotelMessages: hotelsResult.messages,
    hasHotels: hotelsResult.ids?.length !== 0 || false,
    hasAll: hotelsResult.ids?.length === hotelsResult.count || false,
    totalCount: hotelsResult.totalCount,
    filteredHotelCount: hotelsResult.count,
    error: hotelsResult.error,
    isLoadingInitialHotels,
    isLoadingMoreHotels,
    isLoadingRefetchHotels,
    fetchMoreHotels,
    refetchHotels,
    fetchAllHotels,
  };
};
