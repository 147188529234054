import React from 'react';
import styled from 'styled-components';
import { colors } from '@bwoty-web/styleguide/variables/colors';
import { PriceSlider } from '@bwoty-web/ui-kit';
import SliderWrapper from './SliderWrapper';
import { useMucd } from '../../hooks/useMucd';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

const NoPrice = styled.div`
  font-size: 14px;
  margin-left: 10px;
  color: ${colors.mediumGray};
`;

export const FilterPriceDumb = (props) => {
  const { label, noPricesText, searchParams, tripPrice, refetchHotels } = props;

  const hidden = searchParams.filter === 'true';

  const mucd = useMucd();

  const handleChange = (priceTo) => {
    const price = priceTo >= tripPrice?.max ? '' : priceTo;
    refetchHotels({
      newSearchParams: { priceTo: price },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        params: {
          travelFilterCategory: 'Price',
          travelFilterValue: 'Price',
        },
      },
    });
  };

  if (tripPrice === {} || (!tripPrice.min && !tripPrice.max) || tripPrice.max === tripPrice.min) {
    return <NoPrice>{noPricesText}</NoPrice>;
  }

  const { priceTo } = searchParams;

  return (
    <div>
      <SliderWrapper>
        <PriceSlider
          uniqueId="price_filter"
          max={tripPrice?.max}
          min={tripPrice?.min}
          value={parseInt(priceTo, 10) || tripPrice?.max}
          onChange={handleChange}
          valueLabel={label || 'Up to'}
          hidden={hidden}
          mucd={mucd}
        />
      </SliderWrapper>
    </div>
  );
};

FilterPriceDumb.defaultProps = defaultProps;

export default FilterPriceDumb;
