import React from 'react';
import { WaveRating, GlobeRating } from '@bwoty-web/styleguide';
import Checkbox from './Checkbox';
import Heading from './Heading';
import { useMucd } from '../../hooks/useMucd';
import { useSiteId } from '../../hooks/useSiteId';
import { ANALYTICS_DATA_TYPES } from '../../hooks/useAnalytics';

const defaultProps = {
  prefix: '',
};

const options = [
  { key: 'r5', value: 10 },
  { key: 'r4', value: 8 },
  { key: 'r3', value: 6 },
  { key: 'r2', value: 4 },
];

const FilterRating = (props) => {
  const { heading, prefix, counts, searchParams, refetchHotels } = props;

  const mucd = useMucd();
  const siteId = useSiteId();
  const { filters = {} } = searchParams;

  const handleChange = (event, key, value) => {
    refetchHotels({
      newSearchParams: { filters: { [key]: !!counts?.[key] && event.target.checked } },
      analyticsData: {
        type: ANALYTICS_DATA_TYPES.FILTER,
        shouldSendEvent: !!counts?.[key] && event.target.checked,
        params: {
          travelFilterCategory: 'Grades',
          travelFilterValue: `Hotel standard ${value / 2}`,
        },
      },
    });
  };

  const Rating = mucd === 'ds' ? GlobeRating : WaveRating;

  return (
    <div>
      <Heading>{heading}</Heading>
      {options.map(({ key, value }) => {
        return (
          <Checkbox
            key={key}
            checked={filters[key]}
            onChange={(event) => handleChange(event, key, value)}
            uniqueId={`${prefix}${key}`}
            disabled={!counts?.[key] && !filters[key]}
          >
            <Rating rating={value} siteId={siteId} /> ({counts?.[key] || 0})
          </Checkbox>
        );
      })}
    </div>
  );
};

FilterRating.defaultProps = defaultProps;

export default FilterRating;
